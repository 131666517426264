import React, { useState } from "react";
import styled from "styled-components";
import { ClickAwayListener, Tooltip, useMediaQuery, useTheme } from "@mui/material";

const CustomTooltipContainer = styled.div`
  width: ${props => (props.width ? props.width : "inherit")};
  height: inherit;
  box-sizing: border-box;
`;

/** Если большое количество Tooltip MUI в DOM-дереве, появляется проблема производительности
 * Найдено решение, делать рендер Tooltip только в том случае, если пользователь навел на него
 * https://github.com/mui/material-ui/issues/27057
 * - enablePressDuration - рекомендуется включить, если у дочернего элемента есть событие onClick или ссылка */
export const CustomMUITooltip = ({ width, longPressDuration = 1000, enablePressDuration = false, children, ...rest }) => {
  const theme = useTheme();
  const isMobileDevices = useMediaQuery(theme.breakpoints.down("md"));
  const [renderTooltip, setRenderTooltip] = useState(false);
  let pressTimer;

  const handleOnOpen = () => {
    if (isMobileDevices && enablePressDuration) {
      return;
    }
    setRenderTooltip(true);
  };
  const handleOnClose = () => {
    setRenderTooltip(false);
  };

  const handleOnMouseEnter = () => {
    if (isMobileDevices) {
      return;
    }
    handleOnOpen();
  };
  const handleOnMouseLeave = () => {
    if (isMobileDevices) {
      return;
    }
    handleOnClose();
  };

  const handleOnStartTouch = () => {
    if (!isMobileDevices || !enablePressDuration) {
      return;
    }
    clearPressTimer();
    pressTimer = setTimeout(() => {
      setRenderTooltip(true);
    }, longPressDuration);
  };
  const clearPressTimer = () => {
    if (!isMobileDevices || !enablePressDuration) {
      return;
    }
    clearTimeout(pressTimer);
  };

  return (
    <ClickAwayListener onClickAway={handleOnClose}>
      <CustomTooltipContainer
        onClick={handleOnOpen}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onTouchStart={handleOnStartTouch}
        onTouchEnd={clearPressTimer}
        onTouchCancel={clearPressTimer}
        width={width}>
        {!renderTooltip && children}
        {renderTooltip && (
          <Tooltip open={isMobileDevices ? renderTooltip : undefined} {...rest}>
            {children}
          </Tooltip>
        )}
      </CustomTooltipContainer>
    </ClickAwayListener>
  );
};
