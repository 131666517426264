import { jwtDecode } from "jwt-decode";

export const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  try {
    const tokenData = jwtDecode(token);
    return true;
  } catch {
    return false;
  }
}