import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import toast, { useToaster } from "react-hot-toast";

import { removeToast } from "./toastStore";
import { colors } from "../styleguide/colors";
import { parseMilliseconds } from "../../utils/parseMilliseconds";

import closeIcon from "./icons/close.inline.svg";

export const defaultToastDuration = parseMilliseconds("1s");

export const DefaultToastBody = withModifiers({
  default: () => `
        &::before {
            background: ${colors.magenta};
        }
    `
})(
  modifier =>
    styled.div`
      width: 100%;
      position: relative;
      box-sizing: border-box;
      padding: 12px 32px 12px 16px;
      background: #fff;
      border-radius: 3px;
      color: #595959;
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.080305));
      z-index: 1;
      overflow: hidden;
      width: 300px;

      &::before {
        content: "";
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      ${modifier}
    `
);

const Close = styled(closeIcon)`
  position: absolute;
  width: 11px;
  height: 11px;
  top: 4px;
  right: 4px;
  cursor: pointer;
  padding: 10px;
`;

const GradientContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 9999;

  &:before,
  &:after {
    display: none;
    content: "";
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    z-index: 1000;
  }

  &:after {
    background: linear-gradient(0deg, #f5e1e3, transparent);
    bottom: 0;
  }

  &:before {
    top: 0;
    background: linear-gradient(180deg, #f5e1e3, transparent);
  }
`;

const JustifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  // height: calc(100vh - 500px);
`;

const ToasterContainer = styled.div`
  // padding: 35px 0;
  // overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ToastContainer = styled.div`
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const Toast = props => {
  const { toastData, onClose, onClick, children, type } = props;

  const ToastBody = (toastData.container && toastData.container.component) || DefaultToastBody;

  return (
    <ToastBody
      modifiers={type}
      onClick={() => onClick?.(toastData)}
      {...toastData.container && toastData.container.props}>
      {children}
      <Close
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          removeToast(toastObject => toastObject.toastId === toastData.id);
          toast.dismiss(toastData.id);

          if (onClose) {
            onClose();
          }
        }}
      />
    </ToastBody>
  );
};

export const Toaster = ({ limit = 3 }) => {
  const $container = useRef();

  const {
    toasts,
    handlers: { startPause, endPause, calculateOffset, updateHeight }
  } = useToaster({
    duration: defaultToastDuration
  });

  useEffect(() => {
    setTimeout(() => {
      if ($container.current) {
        $container.current.scrollTop = $container.current?.scrollHeight;
      }
    });
  }, []);

  return (
    <GradientContainer>
      <JustifyContainer>
        <ToasterContainer
          ref={$container}
          className="toaster"
          onMouseEnter={startPause}
          onMouseLeave={endPause}>
          {toasts
            .filter((_, i) => i < limit)
            .map(item => {
              const offset = calculateOffset(item, {
                reverseOrder: false
              });

              const ref = el => {
                if (el && typeof item.height !== "number") {
                  const height = el.getBoundingClientRect().height;
                  updateHeight(item.id, height);
                }
              };

              return (
                <ToastContainer
                  key={item.id}
                  ref={ref}
                  className="toast"
                  style={{
                    transform: `translateY(${offset}px)`,
                    animation: item.animation
                      ? item.animation(item.visible)
                      : item.visible
                      ? "toast-anim-in 0.5s ease-in-out forwards"
                      : "toast-anim-out 0.8s ease-in-out forwards"
                  }}
                  {...item.ariaProps}>
                  {item.message(item)}
                </ToastContainer>
              );
            })}
        </ToasterContainer>
      </JustifyContainer>
    </GradientContainer>
  );
};
