// @flow
import React from "react";
import _ from "lodash";
import { CustomMUITooltip } from "../components/custom-MUI-tooltip/CustomMUITooltip";

export const NUMBER_FIELD_STRING_PARAMS = [
  'per_page',
  'current_page',
  'organization__in',
  'doctor_organization__doctor__in',
  'doctor_organization__organization',
  'doctor_organization__organization__in'
];

export const sortingToParamsTable = (sorting) => {
  if (!sorting) {
    return [];
  }
  return sorting.map((value) => {
    const splitString = value.split('-');
    if (splitString.length === 2) {
      return {
        id: splitString[1],
        desc: true
      };
    } else {
      return {
        id: splitString[0],
        desc: false
      };
    }
  })
}

export const sortingToString = (sorting) => {
  if (!sorting) {
    return '';
  }

  return sorting.map((item) => {
    return item.desc ? `-${item.id}` : item.id;
  }, [])
}

export const sortingToParams = (sorting) => {
  if (!sorting.length) {
    return '';
  }

  return sorting.reduce((acc, item, index) => {
    if (index === 0) {
      acc += item;
    } else {
      acc += `;${item}`;
    }
    return acc;
  }, 'sorting=')
}

export const filtersStoreToFiltersTable = (filters, columns) => {
  if (!filters) {
    return [];
  }
  return Object.keys(filters).map((key) => {
    const column = columns.find((item) => {
      /** regex /_/g очищает все символы "_" в строке */
      /** Для колонок, где filterFn === "" */
      if (item.accessorKey === key) {
        return item;
      }
      const filterFn = item._filterFn || item.filterFn;
      const keySplit = key.split(/_/g);
      if (keySplit.find((item) => item === filterFn)) {
        return keySplit.filter((item) => item !== filterFn).join("") === item.accessorKey.replace(/_/g, '');
      }
    });

    let newKey = null;
    const filterFn = column._filterFn || column.filterFn;
    if (filterFn) {
       newKey = key.replace(`__${filterFn}`, '');
    } else {
      newKey = key;
    }
    return { id: newKey, value: filters[key]};
  })
}
export const stringToParams = (paramsArrayString, isColumnPinning) => {
  if (!paramsArrayString) {
    return {};
  }
  return paramsArrayString.reduce((acc, value) => {
    const splitString = value.split('|');
    let newValue = (splitString[0].includes('__in') || isColumnPinning)  ? decodeURI(splitString[1]).split(',').map((item) => {
      if (NUMBER_FIELD_STRING_PARAMS.includes(splitString[0])) {
        return parseInt(item);
      }
      return item;
    }) : (NUMBER_FIELD_STRING_PARAMS.includes(splitString[0]) ? parseInt(decodeURI(splitString[1])) : decodeURI(splitString[1]));
    if (newValue === 'true') {
      newValue = true;
    }
    if (newValue === 'false') {
      newValue = false;
    }
    acc[splitString[0]] = newValue;
    return acc;
  }, {})
}

export const filtersToParams = (filters, type) => {
  if (!_.size(filters)) {
    return '';
  }

  const newFilters = Object.keys(filters).filter((key) => {
    if (!filters[key] || (Array.isArray(filters.key) && !filters[key].length)) {
      return false;
    }
    return true;
  });
  if (!newFilters.length) {
    return '';
  }
  return newFilters.reduce((acc, key, index) => {
    if (index === 0) {
      acc += `${key}|${filters[key]}`;
    } else {
      acc += `;${key}|${filters[key]}`;
    }
    return acc;
  }, `${type}=`)
}

export const getMeta = (meta) => {
  if (_.size(meta)) {
    return {
      pageIndex: parseInt(meta.current_page - 1),
      pageSize: parseInt(meta.per_page || 10),
      total_items: parseInt(meta.total_items),
    }
  }
  return {};
}

export const paramsMeta = (meta) => {
  return {
    current_page: meta.pageIndex + 1,
    per_page: meta.pageSize || 10,
    total_items: meta.total_items,
  }
}
export const metaToParams = (meta) => {
  if (_.size(meta)) {
    const newMeta = paramsMeta(meta);
    return `meta=current_page|${newMeta.current_page};per_page|${newMeta.per_page};total_items|${newMeta.total_items}`;
  }
  return '';
}

export const paramsToMeta = (meta) => {
  return `current_page|${meta.current_page};per_page|${meta.per_page}`;
}

export const stringifyAllParams = (params) => {
  let stringParams = '';
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      if (stringParams) {
        stringParams += `&${params[key]}`
      } else {
        stringParams += `${params[key]}`
      }
    }
  })
  return stringParams;
}

export const parseAllParams = (params, columns) => {
  const parseParams = {
    sorting: [],
    meta: {},
    filters: {},
    columnPinning: {},
  };
  params.forEach((param) => {
    const splitParam = param.split('=');
    const splitParamValue = splitParam[1]?.split(';');
    if (splitParam[0] === 'sorting') {
      parseParams.sorting = splitParamValue;
    } else if (splitParam[0] === 'meta') {
      parseParams.meta = stringToParams(splitParamValue);
    } else if (splitParam[0] === 'filters') {
      parseParams.filters = stringToParams(splitParamValue);
    } else if (splitParam[0] === 'globalFilters') {
      parseParams.globalfilters = stringToParams(splitParamValue);
    } else if (splitParam[0] === 'columnPinning') {
      parseParams.columnPinning = stringToParams(splitParamValue, true);
    }

  })
  return parseParams;
};

export const trimLongTextValueField = (value) => {
  return (
    <CustomMUITooltip title={value} width="auto">
      <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: '100%', display: 'block'}}>{value}</span>
    </CustomMUITooltip>
  )
};
